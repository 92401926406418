import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

import { ScrollToTop, ThemeColor } from '../components';
import { AnalyticProvider, ModalContextProvider, ToastContextProvider } from '../contexts';
import { InitialAnalytics } from './initial-analytics';
import { IS_DEV } from '../constants/common';
import { PaymentSuccessMiddleware } from '../middlewares/payment-success/payment-success';
import { RouterProvider } from '../routes/RouterProvider';
import { UserTrackingMiddleware } from '../middlewares/user-tracking/user-tracking';

const growthbook = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.REACT_APP_GROWTHBOOK_DEV_MODE === 'true',
  subscribeToChanges: true,
  onFeatureUsage: (featureKey, result?: { value?: string }) => {
    if (IS_DEV) {
      // eslint-disable-next-line no-console
      console.log('[Feature]', { featureKey, result: result?.value });
    }
  },
});

growthbook.init();

function App() {
  return (
    <GrowthBookProvider growthbook={growthbook}>
      <UserTrackingMiddleware>
        <AnalyticProvider>
          <BrowserRouter>
            <InitialAnalytics>
              <PaymentSuccessMiddleware>
                <ToastContextProvider>
                  <ModalContextProvider>
                    <ScrollToTop />
                    <ThemeColor />
                    <RouterProvider />
                  </ModalContextProvider>
                </ToastContextProvider>
              </PaymentSuccessMiddleware>
            </InitialAnalytics>
          </BrowserRouter>
        </AnalyticProvider>
      </UserTrackingMiddleware>
    </GrowthBookProvider>
  );
}

export default App;
