import { FC, ReactNode, useEffect, useState } from 'react';

import { useGrowthBook } from '@growthbook/growthbook-react';
import Adjust from '@adjustcom/adjust-web-sdk';

import { useAnalytic } from '../contexts';
import { USER_DATA } from '../constants/common';
import { useGtagClientId } from '../hooks/use-gtag-client-id';
import { getLSValue, setLSValue } from '../utils/locale-storage';
import { UserLocalStorageData } from '../types/user';
import { useTimeout } from '../hooks/use-timer';
import useQueryParams from '../hooks/use-query-params';
import { PAYWALL_PLANS, PAYWALL_PLANS_NO_TRIAL, PAYWALL_VERSION } from '../constants/growthbook-features';

const AB_START = 'ab_start';
const TIMEOUT_DELAY = 3000;
const NO_TRIAL_ENDING = 'no_trial';
const CUSTOM_PLANS_ID_KEY = 'plan_price_id';

type Props = {
  children: ReactNode;
};

const InitialAnalytics: FC<Props> = ({ children }) => {
  const userLSData = getLSValue(USER_DATA, true) as UserLocalStorageData;
  const [isTimeoutExpired, setIsTimeoutExpired] = useState(false);

  useTimeout(() => setIsTimeoutExpired(true), TIMEOUT_DELAY);

  const growthbook = useGrowthBook();
  const { setUserProperties, sendAmplitudeEvent, amplitudeInit } = useAnalytic();

  const { gtagClientId } = useGtagClientId();
  const { getQueryParams } = useQueryParams();

  useEffect(() => {
    (async () => {
      if (!growthbook?.ready || !amplitudeInit || !gtagClientId || !userLSData?.user_id) {
        return;
      }

      try {
        const featureUserProperties = Object.keys(growthbook.getFeatures()).reduce<Record<string, unknown>>(
          (acc, key) => {
            const feature = growthbook.evalFeature(key);
            acc[key] = feature.value?.amplitude_key || feature.value;
            return acc;
          },
          {},
        );

        if (featureUserProperties[PAYWALL_VERSION] && typeof featureUserProperties[PAYWALL_VERSION] === 'string') {
          const isNoTrialPlans = featureUserProperties[PAYWALL_VERSION].includes(NO_TRIAL_ENDING);

          featureUserProperties[CUSTOM_PLANS_ID_KEY] = isNoTrialPlans
            ? featureUserProperties[PAYWALL_PLANS_NO_TRIAL]
            : featureUserProperties[PAYWALL_PLANS];
        }

        const webUuid = Adjust.getWebUUID() || '';
        const queryParams = getQueryParams();

        setUserProperties({
          ...featureUserProperties,
          customer_id: userLSData.user_id,
          web_uuid: webUuid,
          campaign: queryParams?.utm_campaign || '',
          content: queryParams?.utm_content || '',
          source: queryParams?.utm_source || '',
          lang: queryParams?.utm_lang || '',
        });

        await growthbook.setAttributes({
          user_id: userLSData.user_id,
          device_id: window.navigator.userAgent,
          device_language: window.navigator.language?.toLowerCase() || 'en',
          browser: window.navigator.userAgent,
          url: window.location.host,
          ...(gtagClientId ? { client_id: gtagClientId } : {}),
        });

        if (getLSValue(AB_START, true)) {
          return;
        }

        sendAmplitudeEvent({
          name: AB_START,
        });

        setLSValue(AB_START, true);
      } catch (error: unknown) {
        console.error('Failed to send initial analytics', error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amplitudeInit, growthbook, gtagClientId, userLSData?.user_id]);

  if (!isTimeoutExpired && !growthbook.ready) {
    return null;
  }

  return <>{children}</>;
};

export { InitialAnalytics };
