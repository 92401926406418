import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useFeatureValue } from '@growthbook/growthbook-react';

import { webApiService } from '../../../services/web-api';
import { useAnalytic } from '../../analytic';
import { Plan } from '../../../types/plan';
import { getLSValue, setLSValue } from '../../../utils/locale-storage';
import { CENTS_IN_DOLLAR, PAYMENT_SUCCESS, USER_DATA } from '../../../constants/common';
import { UserLocalStorageData } from '../../../types/user';
import { getPurchaseSubscriptionOptions } from '../helpers/get-payment-options';
import { useToastContext } from '../../toast';
import { usePurchaseAnalytics } from '../../../hooks/usePurchaseAnalytics';
import {
  PAYMENT_ERROR_DISPLAY,
  SUCCESS_PAGE_VERSION,
  SuccessPageVersion,
} from '../../../constants/growthbook-features';
import { getErrorCodeType } from '../helpers/get-payment-error';
import { usePaymentPlace } from '../../../hooks/use-payment_place';
import { ROUTES } from '../../../routes/constants';
import { useModalContext } from '../../modal';
import { ErrorPopup } from '../components/error-popup';
import { ErrorToast } from '../components/error-toast';

import styles from '../payment.module.scss';

enum PaymentErrorDisplay {
  PAGE = 'page',
  TOAST = 'toast',
  POPUP = 'popup',
}

const usePaymentMethods = (plan: Plan) => {
  const currentUserData = useMemo(() => getLSValue(USER_DATA, true) as UserLocalStorageData, []);
  const { sendAmplitudeEvent, trackFBEvent, setUserProperties, sentryCaptureException } = useAnalytic();
  const sendPurchaseEvent = usePurchaseAnalytics();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleOpenToast } = useToastContext();
  const { handleOpenModal } = useModalContext();
  const errorHandlerVersion = useFeatureValue(PAYMENT_ERROR_DISPLAY, PaymentErrorDisplay.TOAST) as PaymentErrorDisplay;
  const successPageVersion = useFeatureValue(SUCCESS_PAGE_VERSION, SuccessPageVersion.v1);

  const place = usePaymentPlace();

  const onPurchaseSubscribe = async (accountId: string, paymentType: 'stripe' | 'paypal') => {
    try {
      return await webApiService.purchaseSubscriptions(
        getPurchaseSubscriptionOptions({
          billingId: accountId,
          userId: currentUserData.user_id,
          plan,
          paymentType,
        }),
      );
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const onPaymentError = async (params: { code: string; declineCode?: string; method: string }): Promise<void> => {
    const price = (Number(plan?.trialPrice) || Number(plan?.price)) / CENTS_IN_DOLLAR;

    sendAmplitudeEvent({
      name: 'payment_complete',
      props: {
        result: 'failed',
        error_code: params.code || 'none',
        decline_code: params.declineCode || 'none',
        placement: place,
        price: price,
        period: plan?.planPeriod,
      },
    });

    setUserProperties({
      payment_status: 'error',
      payment_method: params.method,
      payment_placement: place,
    });

    await trackFBEvent({ name: 'complete_purchase' });

    sentryCaptureException(params.code);

    const errorCodeType = getErrorCodeType(params.code, params.declineCode || '');

    switch (errorHandlerVersion) {
      case PaymentErrorDisplay.TOAST:
        handleOpenToast({
          component: <ErrorToast errorCodeType={errorCodeType} />,
          autoClose: 10000,
        });
        break;

      case PaymentErrorDisplay.POPUP:
        handleOpenModal({
          backdropClassName: styles.backdrop,
          component: ({ onClose }) => <ErrorPopup errorCodeType={errorCodeType} price={price} onClose={onClose} />,
        });
        break;

      default:
        navigate({
          pathname: ROUTES.Failed,
          search: location.search,
        });
    }
  };

  const onPaymentSuccess = async (params: {
    purchaseId: string;
    paymentAmount?: number;
    method: string;
  }): Promise<void> => {
    const { purchaseId, paymentAmount, method } = params;
    sendAmplitudeEvent({
      name: 'payment_complete',
      props: {
        result: 'success',
        placement: place,
        price: (paymentAmount || Number(plan?.trialPrice) || Number(plan?.price)) / CENTS_IN_DOLLAR,
        period: plan?.planPeriod,
      },
    });

    setUserProperties({
      payment_status: 'error',
      payment_method: method,
      payment_placement: place,
    });

    await trackFBEvent({ name: 'complete_purchase' });
    await trackFBEvent({ name: 'complete_purchase_success' });

    await webApiService.purchaseConfirm(purchaseId);

    const amount = paymentAmount ? paymentAmount / CENTS_IN_DOLLAR : undefined;
    sendPurchaseEvent({ value: amount, plan });

    setLSValue(PAYMENT_SUCCESS, true);

    const currentParams = new URLSearchParams(location.search);
    currentParams.set('version', successPageVersion);

    navigate({
      pathname: ROUTES.Success,
      search: currentParams.toString(),
    });
  };

  const onCheckoutSubmit = async (paymentType: string): Promise<void> => {
    sendAmplitudeEvent({
      name: 'payment_tap_pay',
      props: {
        method: paymentType,
        placement: place,
        price: (Number(plan?.trialPrice) || Number(plan?.price)) / CENTS_IN_DOLLAR,
        period: plan?.planPeriod,
      },
    });

    await trackFBEvent({ name: 'start_purchase' });
  };

  return {
    onPurchaseSubscribe,
    onPaymentError,
    onPaymentSuccess,
    onCheckoutSubmit,
  };
};

export { usePaymentMethods };
