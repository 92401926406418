import React, { FC, useEffect, useState } from 'react';
import { ConfigResponse } from 'src/services/web-api';
import { Plan } from 'src/types/plan';
import { Button, BUTTON_VARIANTS } from 'src/components/button';

import {
  loadStripe,
  StripeExpressCheckoutElementConfirmEvent,
  StripeExpressCheckoutElementOptions,
} from '@stripe/stripe-js';
import { Elements, ExpressCheckoutElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useFeatureValue } from '@growthbook/growthbook-react';

import { usePaymentMethods } from '../../hooks/use-payment-methods';
import { ROUTES } from '../../../../routes/constants';
import { SUCCESS_PAGE_VERSION, SuccessPageVersion } from '../../../../constants/growthbook-features';

const DEFAULT_STYLE_OPTIONS = {
  paymentMethods: {
    link: 'never',
    amazonPay: 'never',
    paypal: 'never',
  },
  buttonHeight: 55, // 55px is a max value
} as StripeExpressCheckoutElementOptions;

type Props = {
  paymentConfig: ConfigResponse;
  plan: Plan;
  styleOptions?: StripeExpressCheckoutElementOptions;
  onClick?: (method?: string) => void;
};

const AppleGooglePayButtonComponent: FC<Props> = ({
  plan,
  paymentConfig,
  onClick,
  styleOptions = DEFAULT_STYLE_OPTIONS,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const elements = useElements();
  const stripe = useStripe();

  const successPageVersion = useFeatureValue(SUCCESS_PAGE_VERSION, SuccessPageVersion.v1);

  const { onPaymentError, onPaymentSuccess, onPurchaseSubscribe, onCheckoutSubmit } = usePaymentMethods(plan);

  useEffect(() => {
    if (elements) {
      const element = elements.getElement('payment');
      element?.on('ready', () => {
        setIsLoading(false);
      });
    }
  }, [elements, setIsLoading]);

  const handleExpressCheckoutSubmit = async (e: StripeExpressCheckoutElementConfirmEvent) => {
    try {
      if (!stripe || !elements) {
        return;
      }
      setIsLoading(true);

      await onCheckoutSubmit(e?.expressPaymentType || '');

      onClick?.(e?.expressPaymentType);

      await elements.submit();

      const purchase = await onPurchaseSubscribe(paymentConfig.checkout.stripe.account_id, 'stripe');

      if (!purchase) {
        throw Error("Couldn't proceed a purchase");
      }

      const result = await stripe.confirmPayment({
        elements,
        clientSecret: purchase.stripe?.client_secret || '',
        redirect: 'if_required',
        confirmParams: {
          return_url: `${window.location.origin}/${ROUTES.Success}?version=${successPageVersion}`,
          payment_method_data: {
            billing_details: {
              address: {
                country: 'GB',
              },
            },
          },
        },
      });

      if (result.error?.type && result.error?.type !== 'validation_error') {
        await onPaymentError({
          code: result.error?.code || '',
          declineCode: result.error?.decline_code || '',
          method: e?.expressPaymentType,
        });
        return;
      }

      if (result?.paymentIntent?.status === 'succeeded') {
        await onPaymentSuccess({
          purchaseId: purchase.purchase_id,
          paymentAmount: result?.paymentIntent?.amount || 0,
          method: e?.expressPaymentType,
        });
      }
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormReady = () => {
    setIsLoading(false);
  };

  return (
    <div style={{ position: 'relative', height: isLoading ? `${styleOptions.buttonHeight}px` : 'auto' }}>
      {isLoading && (
        <Button
          isLoading={isLoading}
          variant={BUTTON_VARIANTS.SECONDARY}
          type="button"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: `${styleOptions.buttonHeight}px`,
          }}
        >
          .
        </Button>
      )}

      <ExpressCheckoutElement
        onConfirm={handleExpressCheckoutSubmit}
        onReady={handleFormReady}
        options={styleOptions}
      />
    </div>
  );
};

const AppleGooglePayButton: FC<Props> = ({ paymentConfig, plan, onClick }) => (
  <Elements
    stripe={loadStripe(paymentConfig.checkout.stripe.publishable_key)}
    options={{
      mode: 'subscription',
      amount: Number(plan?.trialPrice) || Number(plan?.price),
      currency: plan?.currency?.toLowerCase() || 'usd',
      appearance: {
        variables: {
          borderRadius: '12px',
        },
      },
    }}
  >
    <AppleGooglePayButtonComponent plan={plan} paymentConfig={paymentConfig} onClick={onClick} />
  </Elements>
);

export { AppleGooglePayButton };
