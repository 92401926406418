import React, { FC } from 'react';
import { ReactComponent as GooglePlayLogoIcon } from 'src/assets/icons/google_play_logo.svg';
import AppleStoreLogoIcon from 'src/assets/icons/app_store_logo.webp';
import { Typographies, Typography } from 'src/components';

import { useFeatureValue } from '@growthbook/growthbook-react';

import { IS_PWA_FLOW } from '../../../../../../constants/growthbook-features';

import styles from './footer.module.scss';

const Footer: FC = () => {
  const isPwaFlow = useFeatureValue(IS_PWA_FLOW, false);

  return (
    <div className={styles.container}>
      {isPwaFlow ? (
        <a href={process.env.REACT_APP_WEB} target="_blank" rel="noreferrer noopener" className={styles.button}>
          Go to the App
        </a>
      ) : (
        <>
          <a href={process.env.REACT_APP_GOOGLE_PLAY} className={styles.store}>
            <GooglePlayLogoIcon className={styles.store__icon} />

            <Typography variant={Typographies.BODY_SMALL} className={styles.store__text}>
              Google Play
            </Typography>
          </a>
          <a href={process.env.REACT_APP_APPLE_STORE} className={styles.store}>
            <img src={AppleStoreLogoIcon} alt="" className={styles.store__icon} />

            <Typography variant={Typographies.BODY_SMALL} className={styles.store__text}>
              App Store
            </Typography>
          </a>
        </>
      )}
    </div>
  );
};

export { Footer };
